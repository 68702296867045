import { api } from "./axios";

const getAllFAQ = () => {
  return api.get("v1/faq");
};

const getDetailFAQ = id => {
  return api.get(`v1/faq/detail?id=${id}`);
};
const addFAQ = data => {
  return api.post("v1/faq", data);
};
const updateFAQ = data => {
  return api.patch("v1/faq", data);
};
const deleteFAQ = id => {
  return api.delete(`v1/faq?id=${id}`);
};

export { getAllFAQ, getDetailFAQ, addFAQ, updateFAQ, deleteFAQ };
