<template>
  <div>
    <dashboard-navbar :routes="routes"></dashboard-navbar>
    <div class="content" @click="$sidebar.displaySidebar(false)">
      <div class="container-fluid mt-2">
        <card body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <div class="row d-flex justify-content-between px-2">
              <h2 class="mb-0">
                List All Invoice Withdrawal
                <span
                  class="subheading"
                >(updated on the 1st of every month)</span>
              </h2>
              <base-button
                class="mr-3"
                type="outline-vr"
                size="lg-vr"
                @click="ListInvoiceAgain"
              >Refresh Page</base-button>
            </div>
          </template>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between mb-3">
              <div class="wrapper-select">
                <span>Show</span>
                <div class="select-row">
                  <el-select
                    class="select-primary pagination-select"
                    size="mini"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </div>
                <span>Row</span>
              </div>
              <div class="form-search">
                <base-input v-model="searchQuery" placeholder="Search Records" size="mini"></base-input>
              </div>
            </div>
            <loading-panel v-if="loading.refetch_data" />
            <el-table
              v-else
              :data="queriedData"
              row-key="id"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
              @selection-change="selectionChange"
            >
              <el-table-column v-for="column in tableColumns" :key="column.label" v-bind="column"></el-table-column>
              <el-table-column prop="earnings" label="Total Amount" min-width="100px">
                <div slot-scope="{$index, row}" class="d-flex">
                  <span>{{ 'Rp. ' + row.earnings }}</span>
                </div>
              </el-table-column>
              <el-table-column prop="status" label="Status" min-width="100px">
                <div slot-scope="{$index, row}" class="d-flex">
                  <span
                    class="status"
                    :class="getStatus(row.status)"
                  >{{ getStatusText(row.status) }}</span>
                </div>
              </el-table-column>
              <el-table-column min-width="200px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                  <base-button
                    type="edit-vr"
                    size="sm"
                    :disabled="isWithdrawDisabled(row.status)"
                    @click.native="handleWithdraw(row)"
                  >
                    <span>Withdraw</span>
                  </base-button>
                  <base-button
                    type="success-vr"
                    size="sm"
                    :disabled="isDownloadDisabled(row.status)"
                    @click.native="handleDownloadInvoice(row)"
                  >
                    <span>Download Invoice</span>
                  </base-button>
                  <base-button @click.native="handleDetailIncome(row)" type="detail-vr" size="sm">
                    <span>Detail Income</span>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                <span
                  v-if="selectedRows.length"
                >&nbsp; &nbsp; {{ selectedRows.length }} rows selected</span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            ></base-pagination>
          </div>
        </card>
      </div>
    </div>
    <modal :show.sync="showModalWithdraw" modal-classes="modal-secondary">
      <template slot="header">
        <h6 class="modal-title">Confirm to Withdraw</h6>
      </template>
      <template slot="close-button"></template>
      <span class="body-modal">
        Are you sure to
        <span class="add-withdraw">Withdraw</span> ?
      </span>
      <template slot="footer">
        <div class="text-right">
          <base-button type="outline-vr" class="my-4" @click="showModalWithdraw = false">Cancel</base-button>
          <base-button type="edit-vr" class="my-4" @click="confirmToWithdraw">Withdraw</base-button>
        </div>
      </template>
    </modal>
    <modal :show.sync="loading.withdraw" modal-classes="modal-secondary">
      <loading-panel />
    </modal>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui'
import { BasePagination } from '@/components';
import DashboardNavbar from '@/Layout/DashboardNavbar.vue';
import listInvoiceMixin from './Mixins/listInvoiceMixin'
import { getListInvoice, postWithdraw, generateInvoicePDF } from '@/services/invoice'
import LoadingPanel from '@/components/LoadingPanel.vue';
import { mapGetters } from 'vuex'
import Fuse from "fuse.js";
export default {
  mixins: [listInvoiceMixin],
  name: "ListInvoice",
  components: {
    BasePagination,
    DashboardNavbar,
    LoadingPanel,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      propsToSearch: ['period', 'earnings', 'status'],
      tableColumns: [
        {
          prop: 'period',
          label: 'Period',
          minWidth: 150,
          sortable: true
        },
      ],
      tableData: [],
      selectedRows: [],
      routes: [
        {
          name: "listInvoice",
          alias: "LIST INVOICE WITHDRAWAL"
        },
      ],
      showModalWithdraw: false,
      selectedWithdraw: null,
      loading: {
        refetch_data: false,
        withdraw: false,
      }

    };
  },
  computed: {
    ...mapGetters("auth", ["getInfoAuth"]),
  },
  methods: {
    getStatusText(val) {
      if (val === "NEED ACTION") {
        return 'Need Action'
      } else if (val === "PENDING") {
        return 'Pending'
      } else if (val === "FAILED") {
        return 'Failed'
      } else if (val === "COMPLETED") {
        return 'Success'
      }
    },
    getStatus(val) {
      if (val === "NEED ACTION") {
        return 'need-action'
      } else if (val === "PENDING") {
        return 'pending'
      } else if (val === "FAILED") {
        return 'failed'
      } else if (val === "COMPLETED") {
        return 'success'
      }
      return 'no-status'
    },
    isWithdrawDisabled(val) {
      if (val === "NEED ACTION" || val === "FAILED") {
        return false
      }
      return true
    },
    isDownloadDisabled(val) {
      if (val === "COMPLETED") {
        return false
      }
      return true
    },
    handleDetailIncome(row) {
      this.$router.push({ name: 'listdetailIncome', params: { id: row.id } })
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    },
    handleWithdraw(row) {
      this.selectedWithdraw = row
      this.showModalWithdraw = true
    },
    async confirmToWithdraw() {
      try {
        this.showModalWithdraw = false
        const payload = {
          id: this.selectedWithdraw.id,
          userId: this.getInfoAuth.id,
          period: this.selectedWithdraw.period,
          earnings: this.selectedWithdraw.earnings
        }
        this.loading.withdraw = true
        const { data } = await postWithdraw(payload)
        setTimeout(() => {
          this.tableData = data
          this.loading.withdraw = false
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          });
          this.$notify({
            type: "success",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: "successfully to withdraw"
          });
        }, 200);
      } catch (error) {
        this.loading.withdraw = false
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    async ListInvoiceAgain() {
      try {
        this.loading.refetch_data = true
        const { data } = await getListInvoice()
        this.loading.refetch_data = false
        this.tableData = data
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
        this.$notify({
          type: "success",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "successfully retrieve data"
        });
      } catch (error) {
        this.loading.refetch_data = false
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else if (error === "EMPTY_RESULT") {
          this.tableData = []
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    async ListInvoice() {
      try {
        const { data } = await getListInvoice()
        this.tableData = data
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    async handleDownloadInvoice(val) {
      try {
        const data = await generateInvoicePDF(val.id)
        const blob = new Blob([data], { type: 'application/pdf' });
        const elink = document.createElement("a");
        elink.download = `Invoice ${val.period}`;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href) // release the URL object
        document.body.removeChild(elink);
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    }
  },
  mounted() {
    this.ListInvoice()
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/argon.scss";
.wrapper-nav {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-select {
  margin: 0 5px;
}
.subheading {
  font-weight: 400;
  font-size: 16px;
  color: #6e6b7b;
}
.wrapper-select {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .select-row {
    width: 75px;
    margin-right: 10px;
  }
}
.status {
  &.need-action {
    color: $status-learn-ar-vr;
  }
  &.pending {
    color: $orange-course;
  }
  &.failed {
    color: $status-failed-ar-vr;
  }
  &.success {
    color: $status-pass-ar-vr;
  }
}

.body-modal {
  font-size: 20px;
  font-weight: 600;
  .list-quality {
    display: block;
  }
  .label-quality {
    margin-left: 10px;
    font-size: 20px;
    font-weight: 500;
    color: #222f3e;
  }
}
</style>