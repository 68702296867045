<template>
  <div>
    <dashboard-navbar :routes="routes"></dashboard-navbar>
    <div class="content" @click="$sidebar.displaySidebar(false)">
      <div class="container-fluid mt-2">
        <card body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <div class="row d-flex justify-content-between px-2">
              <h2 class="mb-0">History</h2>
              <router-link
                :to="{ name: 'listPractice' }"
                class="btn base-button btn-outline-vr btn-lg-vr"
              >Back to List Practice</router-link>
            </div>
          </template>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between mb-3">
              <div class="wrapper-select">
                <span>Show</span>
                <div class="select-row">
                  <el-select
                    class="select-primary pagination-select"
                    size="mini"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </div>
                <span>Row</span>
              </div>
              <div class="form-search">
                <base-input v-model="searchQuery" placeholder="Search Records" size="mini"></base-input>
              </div>
            </div>
            <el-table
              :data="queriedData"
              row-key="id"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
              @selection-change="selectionChange"
            >
              <el-table-column v-for="column in tableColumns" :key="column.label" v-bind="column"></el-table-column>
              <!-- <el-table-column prop="status" label="Status" width="120">
                <div slot-scope="{$index, row}" class="d-flex">
                  <span class="status-pratice-done">{{ row.status }}</span>
                </div>
              </el-table-column>-->
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                <span
                  v-if="selectedRows.length"
                >&nbsp; &nbsp; {{ selectedRows.length }} rows selected</span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            ></base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui'
import { BasePagination } from '@/components';
import DashboardNavbar from '@/Layout/DashboardNavbar.vue';
import listPracticeMixin from './Mixins/listPracticeMixin'
import { getListPractice } from '@/services/hololens'
import Fuse from "fuse.js";
export default {
  mixins: [listPracticeMixin],
  name: "ListCourses",
  components: {
    BasePagination,
    DashboardNavbar,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      propsToSearch: ['date', 'user.name', 'user.npaidi', 'course.name', 'nilai', 'description'],
      tableColumns: [
        {
          prop: 'date',
          label: 'Date',
          minWidth: 150,
          sortable: true
        },
        {
          prop: 'user.name',
          label: 'Student Name',
          minWidth: 150,
          sortable: true
        },
        {
          prop: 'user.npaidi',
          label: 'NPA IDI',
          minWidth: 120,
          sortable: true
        },
        {
          prop: 'course.name',
          label: 'Course',
          minWidth: 250,
          sortable: true
        },
        {
          prop: 'nilai',
          label: 'Score',
          minWidth: 120,
          sortable: true
        },
        {
          prop: 'description',
          label: 'Description',
          minWidth: 200,
          sortable: true
        },
      ],
      tableData: [],
      selectedRows: [],
      routes: [
        {
          name: "listPractice",
          alias: "LIST PRACTICE HOLOLENS"
        },
        {
          name: "listPracticeHistory",
          alias: "History"
        }
      ],
    };
  },
  methods: {
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    },
    async getListHololens() {
      try {
        const { data } = await getListPractice(1)
        this.tableData = data
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
  },
  mounted() {
    this.getListHololens()
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/argon.scss";
.wrapper-nav {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-select {
  margin: 0 5px;
}
.wrapper-select {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .select-row {
    width: 75px;
    margin-right: 10px;
  }
}

.status-pratice-done {
  color: $status-pass-ar-vr;
}
</style>