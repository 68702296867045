<template>
  <div>
    <dashboard-navbar :routes="routes"></dashboard-navbar>
    <div class="content" @click="$sidebar.displaySidebar(false)">
      <div class="container-fluid mt-2">
        <section class="cards">
          <h2 class="heading-card">Add New FAQ</h2>
          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <form role="form" @submit.prevent="handleSubmit(handleAddFAQ)">
              <div class="row">
                <div class="col">
                  <base-input
                    label="Question"
                    class="mb-3"
                    name="title"
                    placeholder="Write question here..."
                    v-model="faq.question"
                    :rules="{ required: true }"
                  ></base-input>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label class="form-control-label">Description</label>
                  <vue-editor placeholder="Write answer in here ..." v-model="faq.answer" />
                </div>
              </div>
              <div class="text-right">
                <base-button outline type="primary" class="my-4" @click="$router.go(-1)">Back</base-button>
                <base-button type="success" class="my-4" native-type="submit">Add New FAQ</base-button>
              </div>
            </form>
          </validation-observer>
        </section>
      </div>
    </div>
    <modal :show.sync="showModalAddFaq" modal-classes="modal-secondary">
      <template slot="header">
        <h6 class="modal-title">Add New FAQ</h6>
      </template>
      <template slot="close-button"></template>
      <span class="body-modal">
        Are you sure to
        <span class="add-faq">add a new FAQ</span> ?
      </span>
      <template slot="footer">
        <div class="text-right">
          <base-button outline type="primary" class="my-4" @click="showModalAddFaq = false">Cancel</base-button>
          <base-button type="success" class="my-4" @click="onSubmit">Add New FAQ</base-button>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import DashboardNavbar from '@/Layout/DashboardNavbar.vue';
import { VueEditor } from "vue2-editor";
import { addFAQ } from '@/services/faq'
// import ImageResize from "quill-image-resize-module";
// Quill.register("modules/imageResize", ImageResize);


export default {
  name: "AddCourses",
  components: {
    DashboardNavbar,
    VueEditor
  },
  data() {
    return {
      routes: [
        {
          name: "faq",
          alias: "List FAQ"
        },
        {
          name: "addFAQ",
          alias: "ADD NEW FAQ"
        },
      ],
      faq: {
        question: '',
        asnwer: ''
      },
      showModalAddFaq: false
    }
  },
  methods: {
    handleAddFAQ() {
      this.showModalAddFaq = true
    },
    async onSubmit() {
      try {
        const payload = {
          question: this.faq.question,
          answer: this.faq.answer
        }
        await addFAQ(payload)
        this.$notify({
          type: "success",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "FAQ Successfuly Added"
        });
        this.showModalAddFaq = false
        setTimeout(() => {
          this.$router.push({ name: 'faq' })
        }, 100);
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      } finally {
        this.showModalAddFaq = false
      }
    },
  },
}
</script>
 <style lang="scss" scoped>
@import "@/assets/sass/argon.scss";
.cards {
  background-color: #fff;
  position: relative;
  padding: 20px;
  border-radius: 10px;
  .heading-card {
    font-size: 24px;
    color: #000;
    font-weight: 500;
    margin-bottom: 20px;
  }
}
.body-modal {
  font-size: 20px;
  font-weight: 600;
  .add-faq {
    color: $status-pass-ar-vr;
  }
}
</style>
