import { api } from "./axios";

const getListPractice = status => {
  if (status) {
    return api.get(`hololens/practice?status=${status}&history=true`);
  } else {
    return api.get(`hololens/practice`);
  }
};
const insertNilaiHololens = data => {
  return api.post("hololens/practice", data);
};

export { getListPractice, insertNilaiHololens };
